define("discourse/plugins/discourse-sanitize-header-tag/lib/discourse-markdown/discourse-sanitize-header-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  function setup(helper) {
    helper.registerOptions((opts, siteSettings) => {
      opts.features["discourse-sanitize-header-tag"] = true;
    });
    helper.registerPlugin(md => {
      md.block.ruler.disable(["heading", "lheading"]);
    });
  }
});